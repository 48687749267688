import * as React from 'react';
import { action, computed, observable } from 'mobx';
import { ReactNode } from 'react';
import { inject } from 'mobx-react';
import mStyle from './CalendarLayout.module.scss';
import { JoinClassName } from '../../../utils/string';
import { CalendarLayoutProps, Global } from '../../../constants';
import { FlexLayout } from '../FlexLayout';
import { CalendarItem } from '../../../utils/calendar/Calendar';
import { Date8 } from '../../../utils/time';
import { HolidayCodeModel } from '../../../pages/general/human/HolidayCode/HolidayCode.model';

@inject('actionStore')
export class CalendarLayout<T> extends React.Component<CalendarLayoutProps<T>> {
  @observable holydays: Array<HolidayCodeModel>;

  constructor(props: CalendarLayoutProps<T>, context?: any) {
    super(props, context);
    this.holydays = [];
  }

  componentDidMount() {
    this.retrieveHolidays();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.month.substr(0, 4) !== this.props.month.substr(0, 4)) {
      this.retrieveHolidays();
    }
  }

  @action
  async retrieveHolidays() {
    const { actionStore: api } = this.props;
    const data = await api?.fxExec('retrieve', {
      sub: 'w_pz010',
      year: this.props.month.substr(0, 4),
    });

    if (data?.items) {
      this.holydays = data.items.map((x: any) => new HolidayCodeModel(x));
    }
  }

  @computed
  private get rowCount(): number {
    const { month } = this.props;

    const iYear = parseInt(month.substr(0, 4), 10);
    const iMonth = parseInt(month.substr(4, 2), 10);
    const thisDate = new Date(iYear, iMonth - 1, 1);
    const lastDate = new Date(iYear, iMonth, 0).getDate();
    const monthSWeek = thisDate.getDay();

    return Math.floor((lastDate + monthSWeek - 1) / 7) + 1;
  }

  private static getTitleColor(day: number): string {
    switch (day) {
      case 0:
        return 'var(--color-red)';

      case 6:
        return 'var(--color-blue)';

      default:
        return 'var(--color-secondary)';
    }
  }

  private static getDayTitleColor(date: Date, holiday: HolidayCodeModel | undefined): string {
    if (holiday) return CalendarLayout.getTitleColor(0);
    return CalendarLayout.getTitleColor(date.getDay());
  }

  private getHoliday(date: string): HolidayCodeModel | undefined {
    for (let i = 0; i < this.holydays.length; i += 1) {
      if (parseInt(date, 10) >= parseInt(this.holydays[i].holiday, 10)
        && parseInt(date, 10) <= parseInt(this.holydays[i].holiday2, 10)) {
        return this.holydays[i];
      }
    }
    return undefined;
  }

  private renderCalendar(): Array<ReactNode> {
    const {
      month,
      data,
      render,
      onDateClick,
      onDateDoubleClick,
    } = this.props;
    const rows: Array<ReactNode> = [];
    const iYear = parseInt(month.substr(0, 4), 10);
    const iMonth = parseInt(month.substr(4, 2), 10);
    const date: Date = new Date(iYear, iMonth - 1, 0);
    if (date.getDay() === 6) {
      date.setDate(date.getDate() + 1);
    } else {
      date.setDate(date.getDate() - date.getDay());
    }

    for (let i = 0; i < this.rowCount; i += 1) {
      const cols: Array<ReactNode> = [];

      for (let j = 0; j < 7; j += 1) {
        const calendarItem: CalendarItem = {
          year: date.getFullYear(),
          month: date.getMonth(),
          date: date.getDate(),
          day: date.getDay(),
          data: Date8.make(date),
        };

        const items = data?.filter((x) => x.date === calendarItem.data) || [];
        const holiday = this.getHoliday(calendarItem.data);

        cols.push(
          <FlexLayout
            key={`${i}_${j}`}
            className={[
              mStyle.dayContainer,
            ]}
            onClick={() => onDateClick && onDateClick(calendarItem, items)}
            onDoubleClick={() => onDateDoubleClick && onDateDoubleClick(calendarItem, items)}
          >
            <FlexLayout
              className={JoinClassName.make([
                mStyle.dayContainerBody,
                calendarItem.data.substr(0, 6) !== month ? mStyle.disabled : false,
              ])}
              isVertical={true}
            >
              <div
                className={mStyle.title}
                style={{
                  color: CalendarLayout.getDayTitleColor(date, holiday),
                }}
              >
                <FlexLayout>
                  <FlexLayout size={15}>{calendarItem.date}</FlexLayout>
                  <FlexLayout
                    justify="start"
                    style={{
                      opacity: 0.8,
                    }}
                  >
                    {holiday?.remarks}
                  </FlexLayout>
                </FlexLayout>
              </div>

              <FlexLayout isVertical={true}>
                {render(calendarItem, items)}
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>,
        );

        date.setDate(date.getDate() + 1);
      }

      rows.push(
        <FlexLayout
          key={i}
          className={mStyle.dayRow}
        >
          {cols}
        </FlexLayout>,
      );
    }

    return rows;
  }

  render() {
    const {
      className,
      weight,
      size,
      style,
      // onDateClick,
      // onDateDoubleClick,
    } = this.props;

    const korDays = ['일', '월', '화', '수', '목', '금', '토'];

    return (
      <FlexLayout
        className={JoinClassName.make([className, mStyle.container])}
        weight={weight}
        size={size}
        style={style}
        isVertical={true}
        isScroll={true}
      >
        <FlexLayout
          className={mStyle.header}
          size={Global.LAYOUT_BUTTON_HEIGHT_1}
          align="center"
        >
          {korDays.map((x, i) => (
            <FlexLayout
              key={i}
              style={{
                color: CalendarLayout.getTitleColor(i),
              }}
            >
              {x}
            </FlexLayout>
          ))}
        </FlexLayout>

        {this.renderCalendar()}
      </FlexLayout>
    );
  }
}
