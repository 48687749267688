import * as React from 'react';
import { action } from 'mobx';
import Swal from 'sweetalert2';
import { Today } from '../../../../utils/time';
import { ScheduleModel } from './models/Schedule.model';
import { CalendarLayoutDataModel } from '../../../../models/component';
import {
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import {
  ConfirmType,
  ConfirmTypeName,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { ScheduleTemplate } from './Schedule.template';

export interface PlanEnrollState {
  // 검색 조건
  stmon: string;
  perid: string;
  pernm: string;
  popupPerid: string;
  popupPernm: string;

  // 당직 리스트
  data: Array<CalendarLayoutDataModel<ScheduleModel>>;
  kukcds: Array<any>; // 부서구분 리스트
  wkcds: Array<any>; // 업무구분 리스트
  kukcd: string;

  // 당직 모달
  isScheduleMaintenanceModalVisible?: boolean;
  isScheduleGreetingModalVisible?: boolean;
  schedulePopupList?: Array<ScheduleModel>;
  schedulePopupDate: string;
  focusedSchedulePopup?: ScheduleModel,
}

export enum ScheduleItemChangeTypes {
  WKCD,
}

export enum ScheduleItemChangeTypeNames {
  wkcd,
}

const upflags = [
  { value: '001', color: '#000000' },
  { value: '002', color: '#5B1A86' },
  { value: '003', color: '#7F7A2C' },
  { value: '004', color: '#ED709B' },
  { value: '005', color: '#A77450' },
  { value: '006', color: '#881B20' },
  { value: '007', color: '#FAC96C' },
];

/**
 * 컨트롤러
 * @window w_tb_e501
 * @category 스케쥴관리
 */

// eslint-disable-next-line max-len
export class Schedule extends React.Component<PageProps, PlanEnrollState> implements PageToolEvents {
  updatedRows?: Array<ScheduleModel>;

  popupTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const { user } = this.props.publicStore;

    // state 기본값 정의
    this.state = props.state || {
      stmon: Today.yearMon(),
      perid: user.perid,
      pernm: user.pernm,
      popupPerid: '',
      popupPernm: '',
      wkcds: '',
      focusedSchedulePopup: '',
      schedulePopupDate: '',
      schedulePopupList: [],
    };
  }

  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const data = {
      kukcds: await api.dropdown('wf_dd_pz008'),
      wkcds: await api.dropdown('wf_dd_e021'),
    };

    this.setState({
      kukcds: data?.kukcds.items,
      wkcds: data?.wkcds.items,
    });

    await this.onRetrieveEvent();
  }

  // eslint-disable-next-line class-methods-use-this
  getItemColor(item: string): string {
    return upflags.filter((y) => y.value === item)[0]?.color;
  }

  @action
  async onRetrieveEvent() {
    const { user } = this.props.publicStore;
    this.setState({
      kukcd: user.kukcd,
      data: [],
    }, async () => {
      // 달력
      const { actionStore: api } = this.props;
      const data = await api.retrieve({
        stmon: this.state.stmon,
        perid: this.state.perid,
      });

      data?.items && this.setState({
        data: data.items.map((x: any) => (
          new CalendarLayoutDataModel(x.plandate, new ScheduleModel(x)))),
      });
    });
  }

  @action
  onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.data?.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-success',
      },
      buttonsStyling: false,
    });

    // @ts-ignore
    swalWithBootstrapButtons.fire({
      title: '선택',
      html: '인쇄할 사이즈를 선택해주세요',
      icon: ConfirmTypeName[ConfirmType.QUESTION],
      showCancelButton: true,
      confirmButtonText: 'A4',
      cancelButtonText: 'A3',
      reverseButtons: true,
    // @ts-ignore
    }).then((result: { value: any; dismiss: Swal.DismissReason; }) => {
      if (result.value) {
        this.doPrint('a4');
      } else if (
        result.dismiss === Swal.DismissReason.backdrop
      ) {
        ConfirmWarning.show('취소', '취소하였습니다.');
      } else {
        this.doPrint('a3');
      }
    });
  }

  doPrint(gubun: string) {
    const { actionStore: api } = this.props;
    api.printWithElmanManager({
      stmon: this.state.stmon,
      perid: this.state.perid,
      actcd: '',
      divicd: '',
      state: '',
      gubun,
      contgubun: '%',
    });
  }

  @action
  onRowFocusEvent(item:ScheduleModel) {
    this.setState({ focusedSchedulePopup: item });
  }

  @action
  async closeScheduleModal() {
    this.setState({
      schedulePopupDate: '',
      schedulePopupList: [],
      isScheduleGreetingModalVisible: false,
      isScheduleMaintenanceModalVisible: false,
    });
    this.updatedRows = [];
    this.onRetrieveEvent();
  }

  @action
  async openScheduleModal(date: string) {
    this.setState({
      isScheduleMaintenanceModalVisible: true,
      schedulePopupDate: date,
    }, () => this.modalRetrieve());
  }

  @action
  async modalRetrieve() {
    const { actionStore: api } = this.props;
    const data = await api.retrieve(
      {
        sub: 'w_popup_e501',
        plandate: this.state.schedulePopupDate,
        perid: this.state.perid,
      },
    );

    if (data.items) {
      this.setState({
        schedulePopupList: data.items,
      }, async () => {
        await this.popupTable.current?.update();
        this.popupTable.current?.setFocus(0, 2);
      });
    } else {
      this.setState({
        schedulePopupList: [],
      }, () => this.popupTable.current?.update());
    }
  }

  @action
  async modalDelete() {
    const { actionStore: api } = this.props;
    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_popup_e501',
      plandate: this.state.focusedSchedulePopup?.plandate,
      plannum: this.state.focusedSchedulePopup?.plannum,
      upflag: this.state.focusedSchedulePopup?.upflag,
    }) && await this.modalRetrieve();

    this.updatedRows = [];
  }

  @action
  async modalSave() {
    const { actionStore: api } = this.props;

    if (this.state.schedulePopupList?.length) {
      ConfirmSuccess.showPbMessage(await api.fxExec('save',
        {
          sub: 'w_popup_e501',
          items: this.state.schedulePopupList,
        }));
    }

    await this.closeScheduleModal();
  }

  @action
  async modalNew() {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;
    const seq:number | undefined = this.state.schedulePopupList?.length;

    const data = await api.fxExec('new',
      {
        sub: 'w_popup_e501',
        planmon: this.state.stmon,
      });
    data.perid = user.perid;
    data.pernm = user.pernm;

    if (data) {
      const newModel = new ScheduleModel({
        ...data,
        plandate: this.state.schedulePopupDate,
        // eslint-disable-next-line no-nested-ternary
        plannum: seq! < 10 ? `00${seq! + 1}` : seq! < 100 ? `0${seq! + 1}` : (seq! + 1).toString(),
      }, true);

      this.setState({
        schedulePopupList: [
          newModel,
          ...this.state.schedulePopupList!,
        ],
        focusedSchedulePopup: newModel,
      }, async () => {
        await this.popupTable.current?.update();
        this.popupTable.current?.setFocus(0, 2);
      });
    }
  }

  @action
  // 부서계획 불러오기
  // eslint-disable-next-line class-methods-use-this
  async roadSchedule() {
    // 서버 측 기능 구현 후 테스트
    ConfirmWarning.show('경고', '해당 기능 준비중입니다.');

    // const { actionStore: api } = this.props;
    //
    // const data = await api.fxExec('wb_diviplan',
    //   {
    //     sub: 'w_popup_e501',
    //     plandate: this.state.schedulePopupDate,
    //     perid: this.state.popupPerid,
    //     pernm: this.state.popupPernm,
    //   });
    //
    // if (data && data.items) {
    //   this.setState({
    //     schedulePopupList: [
    //       ...this.state.schedulePopupList!,
    //       ...data.items,
    //     ],
    //   }, () => this.popupTable.current?.update());
    // }
  }

  @action
  async itemChanged(type: number, item?: any) {
    const { actionStore: api } = this.props;

    // eslint-disable-next-line max-len
    const focusedDetailIndex = this.state.schedulePopupList?.findIndex((x) => x.plannum === item.plannum);
    let data = { new: undefined };
    const params = {
      itemname: ScheduleItemChangeTypeNames[type],
      data: '',
      new: this.state.focusedSchedulePopup?.new,
      sub: 'w_popup_e501',
    };
    if (type === ScheduleItemChangeTypeNames.wkcd) {
      data = await api.fxExec(
        'dw_list_itemchanged',
        {
          ...params,
          data: item.wkcd,
        },
      );
    }

    // 디테일 테이블에서 수정되어야하는 로우를 찾아 값 변경
    data && this.setState({
      focusedSchedulePopup: new ScheduleModel({
        ...this.state.focusedSchedulePopup,
      }, false),
      // eslint-disable-next-line max-len
      schedulePopupList: this.state.schedulePopupList?.map((x: any, index: number) => (
        index === focusedDetailIndex
          ? new ScheduleModel({ ...x, ...data }, data.new === '1') : new ScheduleModel(x)
      )),
    }, () => this.popupTable.current?.update(false));
  }

  @action
  onUpdatedRows(rows: Array<ScheduleModel>, updatedRows: Array<ScheduleModel>) {
    this.updatedRows = updatedRows;
    this.setState({ schedulePopupList: rows });
  }

  render() {
    return (
      <ScheduleTemplate
        scope={this}
      />
    );
  }
}
