import * as React from 'react';
import { action } from 'mobx';
import { RefObject } from 'react';
import { PageProps, PageToolEvents, RetrieveFocusType } from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import { StatusTemplate } from './Status.template';
import { PageComponent } from '../../../../utils';
import { GridLayout } from '../../../../components';
import { Date6, Today } from '../../../../utils/time';
import StatusTabModel from './models/StatusTabModel';
import { ConfirmWarning } from '../../../../utils/confirm';


export const Compyns = [
  { value: '%', remark: '전체', color: 'inherit' },
  { value: '0', remark: '견적', color: 'inherit' },
  { value: '1', remark: '공사', color: 'var(--color-green)' },
  { value: '2', remark: '매출', color: 'var(--color-blue)' },
  { value: '3', remark: '폐기', color: 'var(--color-red)' },
  { value: '4', remark: '수주', color: 'var(--color-red)' },
  { value: '5', remark: '수금', color: 'var(--color-violet)' },
  // { value: '9', remark: '계획', color: 'inherit' },
  // { value: ' ', remark: '견적전', color: 'inherit' },
];

export const Gubuns = [
  { value: '%', remark: '전체' },
  { value: '11', remark: '유지보수' },
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
  { value: '14', remark: '설치공사' },
  { value: '15', remark: '리모델링' },
  { value: '16', remark: '현대엘리베이터(공)' },
  { value: '19', remark: '기타수입' },
];

interface SampleState {
  searchQuery: string;
  stdate: string;
  enddate: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;
  wkactcd: string;
  wkactnm: string;
  projno: string;
  projnm: string;
  compyn: string;
  gubun: string;

  wkactcds: Array<any>;
  gubuns: Array<any>;

  dataDivicd: Array<StatusTabModel>;
  dataDetail: Array<StatusTabModel>;
  focused?: any;

  divicdCompamt_tot?: string;
  divicdSaleamt_tot?: string;
  divicdSamt_tot?: string;
  divicdTamt_tot?: string;

  detailAddamt_tot?: string;
  detailCompamt_tot?: string;
  detailMisamt_tot?: string;
  detailSamt_tot?: string;
}


/**
 * 컨트롤러
 * @window w_tb_e471w
 * @category 공사현황
 */
export class Status extends PageComponent<PageProps, SampleState>
  implements PageToolEvents {
  infinityDivicd?: InfinityRetrieve;

  gridDivicd: RefObject<GridLayout> = React.createRef();

  gridDivicdFocus?: StatusTabModel;

  gridDivicdFocusIndex: number = 0;

  infinityDetail?: InfinityRetrieve;

  gridDetail: RefObject<GridLayout> = React.createRef();

  gridDetailFocus?: StatusTabModel;

  gridDetailFocusIndex: number = 0;

  tabIndex: number = 0;

  firstOpen: boolean = false;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const { user } = this.props.publicStore;
    let bosuPerid = '';
    let bosuPernm = '';

    if (user.kukcd !== '01') {
      bosuPerid = user.perid;
      bosuPernm = user.pernm;
    }

    this.state = props.state || {
      searchQuery: '',
      stdate: `${Date6.make()}01`,
      enddate: Today.date(),
      divicd: '',
      divinm: '',
      perid: bosuPerid || '',
      pernm: bosuPernm || '',
      wkactcd: '',
      wkactnm: '',
      projno: '',
      projnm: '',
      compyn: '%',
      gubun: '%',

      dataDivicd: [],
      dataDetail: [],
    };
  }


  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    // 회사구분 리스트

    let data = [];

    data = await api.dropdown('wf_dd_e018_1');
    if (!data) return;
    this.setState({ wkactcds: data.items });

    data = await api.dropdown('wf_dd_da020');
    if (!data) return;
    this.setState({ gubuns: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    const p = {
      as_nm: this.state.searchQuery || '',
      stdate: this.state.stdate || `${Date6.make()}01`,
      enddate: this.state.enddate || Today.date(),
      divicd: this.state.divicd || '%',
      perid: this.state.perid || '%',
      wkactcd: this.state.wkactcd || '%',
      projno: this.state.projno || '%',
      compyn: this.state.compyn || '%',
      gubun: this.state.gubun || '%',
    };

    // 무한 스크롤바 헬퍼 초기화
    if (this.tabIndex === 0) {
      this.infinityDivicd = new InfinityRetrieve(
        p,
        (params) => api.fxExec('tab_1_retrieve', params),
        (items, next) => {
          this.SS({
            dataDivicd: [
              ...this.state.dataDivicd,
              ...items.map((item) => new StatusTabModel(item)),
            ],
          });
          next && next();
        },
        async () => {
          await this.SS({
            dataDivicd: [],
          });
          await this.infinityDivicd?.retrieveAll();
        },
      );
    } else if (this.tabIndex === 1) {
      this.infinityDetail = new InfinityRetrieve(
        p,
        (params) => api.fxExec('tab_2_retrieve', params),
        (items, next) => {
          this.SS({
            dataDetail: [
              ...this.state.dataDetail,
              ...items.map((item) => new StatusTabModel(item)),
            ],
          });
          next && next();
        },
        async () => {
          await this.SS({
            dataDetail: [],
          });
          await this.infinityDetail?.retrieveAll();
        },
      );
    }

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      dataDivicd: [],
      dataDetail: [],
    });

    const index = await this.infinityDivicd?.retrieveTo(['divinm', 'pernm'],
      [this.state.focused?.divinm, this.state.focused?.pernm], type, true) || 0;
    const index2 = await this.infinityDetail?.retrieveTo(['divinm', 'costpernm'],
      [this.state.focused?.divinm, this.state.focused?.costpernm], type, true) || 0;

    const divicd = await this.infinityDivicd?.box;
    const detail = await this.infinityDetail?.box;

    await this.SS({
      divicdCompamt_tot: divicd?.compamt_tot,
      divicdSaleamt_tot: divicd?.saleamt_tot,
      divicdSamt_tot: divicd?.samt_tot,
      divicdTamt_tot: divicd?.tamt_tot,
      detailAddamt_tot: detail?.addamt_tot,
      detailCompamt_tot: detail?.compamt_tot,
      detailMisamt_tot: detail?.misamt_tot,
      detailSamt_tot: detail?.samt_tot,
    });
    if (this.state.dataDivicd && this.state.dataDivicd.length > index) {
      this.gridDivicd.current?.setFocus(index);
    }
    if (this.state.dataDetail && this.state.dataDetail.length > index) {
      this.gridDetail.current?.setFocus(index2);
    }
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;

    if (this.tabIndex === 0 && !this.state.dataDivicd.length) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    if (this.tabIndex === 1 && !this.state.dataDetail.length) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.fxPrint(`tab_${this.tabIndex + 1}_print`, {
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery,
      perid: this.state.perid,
      divicd: this.state.divicd,
      compyn: this.state.compyn || '%',
      wkactcd: this.state.wkactcd || '%',
      gubun: this.state.gubun || '%',
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;

    if (this.tabIndex === 0 && !this.state.dataDivicd.length) {
      ConfirmWarning.show('오류', '엑셀 변환할 없습니다.');
      return;
    }

    if (this.tabIndex === 1 && !this.state.dataDetail.length) {
      ConfirmWarning.show('오류', '엑셀 변환할 없습니다.');
      return;
    }

    await api.fxExcel(`tab_${this.tabIndex + 1}_excel`, {
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery,
      perid: this.state.perid,
      divicd: this.state.divicd,
      compyn: this.state.compyn || '%',
      wkactcd: this.state.wkactcd || '%',
      gubun: this.state.gubun || '%',
    });
  }


  @action
  async onDivicdRowFocusEvent(item: StatusTabModel | undefined, index: number) {
    this.gridDetailFocus = item;
    this.gridDetailFocusIndex = index;
  }

  @action
  async onDetailRowFocusEvent(item: StatusTabModel | undefined, index: number) {
    this.gridDetailFocus = item;
    this.gridDetailFocusIndex = index;
  }


  @action
  onTabChange(index: number) {
    this.tabIndex = index;
    if (this.firstOpen === true) {
      this.onRetrieveEvent();
    } else {
      this.firstOpen = true;
    }
  }

  @action
  onClickOpenResist() {
    this.props.publicStore.go(
      '/w_tb_e471',
      {
        stdate: this.state.focused.compdate,
        enddate: this.state.focused.compdate,
        searchQuery: this.state.focused.compdate + this.state.focused?.compnum,
      },
    );
  }


  render() {
    return <StatusTemplate
      scope={this}
    />;
  }
}
