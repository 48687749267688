import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_e073w_01
 * @category 검사신청서
 */

export class ApplicationModel {
  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 선택
   *
   * **data type** varchar(13)
   */
  readonly chk: string;

  /**
   * 신청구분
   *
   * **data type** varchar(5)
   */
  readonly planflag: string;

  /**
   * 계약분류
   *
   * **data type** varchar(10)
   */
  readonly contgubun: string;

  /**
   * 현장코드
   *
   * **data type** varchar(5)
   */
  readonly actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(255)
   */
  readonly actnm: string;

  /**
   * 담당자
   *
   * **data type** varchar(255)
   */
  readonly pernm: string;

  /**
   * 회사코드
   *
   * **data type** varchar(3)
   */
  readonly wkactcd: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(3)
   */
  readonly cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(255)
   */
  readonly cltnm: string;

  /**
   * 검사담당부서
   *
   * **data type** varchar(1)
   */
  readonly kcspnm: string;

  /**
   * 유효기간만료일
   *
   * **data type** varchar(10)
   */
  readonly kcenddate: string;

  /**
   * 유효기간만료월
   *
   * **data type** varchar(8)
   */
  readonly kcendmon: string;

  /**
   * 정렬
   *
   * **data type** varchar(10)
   */
  readonly sort: string;

  /**
   * 신청구분
   *
   * **data type** varchar()
   */
  readonly prtflag: string;

  /**
   * 지역명
   *
   * **data type** varchar()
   */
  readonly areanm: string;

  /**
   * 검사담당부서
   *
   * **data type** varchar()
   */
  readonly gareanm: string;

  /**
   * 검사담당부서fax
   *
   * **data type** varchar()
   */
  readonly faxnum: string;

  /**
   * 검사상태
   *
   * **data type** varchar()
   */
  readonly state: string;

  /**
   * 대수
   *
   * **data type** number()
   */
  readonly qty: number;

  /**
   * 코드
   *
   * **data type** varchar()
   */
  readonly equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar()
   */
  readonly equpnm: string;

  /**
   * 등급
   *
   * **data type** varchar()
   */
  readonly class: string;

  /**
   * 형식
   *
   * **data type** varchar()
   */
  readonly form: string;

  /**
   * 용도
   *
   * **data type** varchar()
   */
  readonly ause: string;

  /**
   * 층수
   *
   * **data type** varchar()
   */
  readonly float: string;

  /**
   * 검사계획일
   *
   * **data type** varchar()
   */
  readonly plandate: string;

  /**
   * 인사코드
   *
   * **data type** varchar()
   */
  readonly perid: string;

  /**
   * 신청비(총) 예정
   *
   * **data type** varchar()
   */
  kcamt_ex_tot: string;

  /**
   * 구분명
   *
   * **data type** varchar()
   */
  evernm: string;

  /**
   * 신청비(기준) 예정
   *
   * **data type** varchar()
   */
  kcamt_ex: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.chk = data.chk || '';
    this.planflag = data.planflag || '';
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.contgubun = data.contgubun || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.pernm = data.pernm || '';
    this.wkactcd = data.wkactcd || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.kcspnm = data.kcspnm || '';
    this.kcenddate = data.kcenddate || '';
    this.kcendmon = data.kcendmon || '';
    this.sort = data.sort || '';
    this.prtflag = data.prtflag || '';
    this.areanm = data.areanm || '';
    this.gareanm = data.gareanm || '';
    this.faxnum = data.faxnum || '';
    this.state = data.state || '';
    this.qty = data.qty || 0;
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.class = data.class || '';
    this.form = data.form || '';
    this.ause = data.ause || '';
    this.float = data.float || '';
    this.plandate = data.plandate || '';
    this.perid = data.perid || '';
    this.kcamt_ex_tot = data.kcamt_extot || '';
    this.evernm = data.evernm || '';
    this.kcamt_ex = data.kcamt_ex || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
