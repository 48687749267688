import * as React from 'react';
import { action } from 'mobx';
import {
  AskType,
  ConfirmType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
  RowUpdate,
} from '../../../../constants';
import { ManageDepositTemplate } from './ManageDeposit.template';
import { ManageDepositModel, ManagePopupModel } from './models';
import {
  GridLayout,
  TableLayout,
} from '../../../../components';
import {
  InfinityRetrieve,
  SearchBinding,
} from '../../../../models';
import { ConfirmWarning } from '../../../../utils/confirm';
import Confirm from '../../../../utils/confirm/Confirm';
import { PageComponent } from '../../../../utils/layout';
import { Format } from '../../../../utils/string';
import { CommonSelectorItem } from '../../../../stores/ModalStore';
import { CltcdPopupModel } from './models/CltcdPopup.model';
import { Update } from '../../../../utils/array';

interface ManageDepositState {
  // 상단 조건
  stdate: string;
  enddate: string;
  searchQuery: string;
  gubun: string;
  spjangcd: string;
  bankcd: string;
  banknm: string;
  accnum: string;
  compflag: string;
  spjangcds?: Array<any>;
  new: string;

  // data
  resuamt: number;
  janamt: string;
  focusIndex: number;
  ManageDepositList: Array<ManageDepositModel>;
  focused?: ManageDepositModel;
  cltcdChk: boolean;

  // modal
  depositVisible?: boolean;
  withdrawVisible?: boolean;
  popupList: Array<ManagePopupModel>;
  data: ManageDepositModel;
  popupFocused: ManagePopupModel;
  bmarflag: string;
  bmar: string;
  janamtChk: boolean;

  // trail
  misamt_tot: string;
  janamt_tot: string;
  mijamt_tot: string;
  inamt_tot: string;
  wbamt_tot: string;
  whamt_tot: string;
  wgamt_tot: string;

  // 거래처 선택 팝업창
  popupSearchQuery: string;
  rtclafi: string;

  cltcdPopupIsvisible: boolean;
  cltcdPopupData: Array<CltcdPopupModel>;

  cltcdPopupFocused: CltcdPopupModel;
  rtclafiChk: string;

  gubuns: Array<any>;
  artModalData: Array<any>;
  isArtModal: boolean;
  artSearchQuery: string;
  artFocusedData: any;
}

/**
 * 컨트롤러
 * @window w_tb_ca642_ats
 * @category 예금관리
 */
export class ManageDeposit extends PageComponent<PageProps, ManageDepositState>
  implements PageToolEvents {
  lastRows?: Array<ManageDepositModel>;

  updatedRows?: Array<ManageDepositModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  table2: React.RefObject<TableLayout> = React.createRef();

  cltcdPopupTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  artModalGrid: React.RefObject<GridLayout> = React.createRef();

  mainTablePosY: number;

  artRowUpdate?: RowUpdate;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    this.mainTablePosY = 0;

    this.state = props.state || {
      stdate: `${year}${month}${date}`,
      enddate: `${year}${month}${date}`,
      searchQuery: '',
      gubun: '%',
      bankcd: '',
      compflag: '%',
      resuamt: 0,
      new: '0',
      focusIndex: 0,
      data: [],
      spjangcds: [],
      mismijchk: false,
      spjangcd: '%',

      popupData: [],

      // trail
      misamt_tot: '0',
      janamt_tot: '0',
      mijamt_tot: '0',
      inamt_tot: '0',
      wbamt_tot: '0',
      whamt_tot: '0',
      wgamt_tot: '0',
      janamtChk: false,

      // 거래처 팝업창
      popupSearchQuery: '',
      rtclafi: '001',
      rtclafiChk: '0',

      gubuns: [],
      artModalData: [],
      isArtModal: false,
      artSearchQuery: '',
      artFocusedData: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 사업장
    const data = await api.dropdown('wf_dd_spjangcd');
    if (!data) return;
    this.setState({ spjangcds: data.items });

    await this.onOpenEvent();
    await this.onRetrieveEvent(RetrieveFocusType.END);
  }

  @action
  async onOpenEvent() {
    const { actionStore: api } = this.props;

    await api.fxExec('open');
  }

  @action
  async onSearchEvent() {
    this.mainTablePosY = 0;
    await this.onRetrieveEvent(RetrieveFocusType.END);
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // const stDate = new Date(Number(this.state.stdate.slice(0, 4)), Number(this.state.stdate.slice(4, 6)), Number(this.state.stdate.slice(6, 8)));
    // const endDate = new Date(Number(this.state.enddate.slice(0, 4)),
    // Number(this.state.enddate.slice(4, 6)), Number(this.state.enddate.slice(6, 8)));
    //
    // const btMs = endDate.getTime() - stDate.getTime();
    // const btDay = btMs / (1000 * 60 * 60 * 24);
    //
    // if (btDay > 91) {
    //   ConfirmWarning.show('확인', '조회 기간이 세달을 넘어가면 안됩니다.');
    //   return;
    // }
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        gubun: this.state.gubun,
        spjangcd: this.state.spjangcd,
        bankcd: this.state.bankcd,
        compflag: this.state.compflag,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          ManageDepositList: [
            ...this.state.ManageDepositList,
            ...items.map((x: any) => new ManageDepositModel(x)),
          ],
        }, async () => {
          await this.table.current?.update(false);
          next && next();
        });
      },
      async () => {
        await this.SS({
          ManageDepositList: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.ManageDepositList && this.state.ManageDepositList?.length > 0) {
          await this.table.current?.update(true);
          this.table.current?.setFocus(0, 3);
        }
      },
    );


    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      ManageDepositList: [],
      cltcdChk: false,
      new: '0',
    }, async () => {
      const index = await this.infinity?.retrieveTo(['snddate', 'sndnum'],
        [this.state.focused?.snddate, this.state.focused?.sndnum], type, true) || 0;
      if (this.state.ManageDepositList && index > 0 && this.state.ManageDepositList.length > index) {
        await this.table.current?.update(true);
        this.table.current?.setFocus(index, 3);
        // this.table.current?.doScrollY({ scrollTop: this.mainTablePosY }, false);
      } else {
        await this.table.current?.update(true);
        this.table.current?.setFocus(0, 3);
      }
    });
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;

    if (this.state.stdate !== this.state.enddate) {
      await ConfirmWarning.show('확인', '신규입력시에는 기간이 같은날로 하여야합니다!\n기간이 시작일로 변경됩니다.');
      this.setState({ enddate: this.state.stdate, new: '0' });
      await this.onRetrieveEvent();
      return;
    }
    this.setState({ new: '1' });

    const data = await api.new(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        bankcd: this.state.bankcd,
        spjangcd: 'ZZ',
      },
    );
    // @ts-ignore
    this.state.spjangcds.length > 1 ? data.spjangcd = '' : data.spjangcd = 'ZZ';

    data && this.setState({
      ManageDepositList: [
        ...this.state.ManageDepositList,
        new ManageDepositModel(data, true),
      ],
    }, async () => {
      await this.table.current?.update(true);
      this.table.current?.setFocus(this.state.ManageDepositList?.length - 1, 1);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;

    // @ts-ignore
    // this.updatedRows[0].new = '1';

    if (await api.save({
      items: this.state.ManageDepositList,
      new: this.state.new,
    }, true)) {
      this.updatedRows = [];
      await this.table.current?.resetUpdates();
      await this.setState({ new: '0' });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `거래일자: ${this.state.focused?.snddate} 거래번호: ${this.state.focused?.sndnum}`;
    await api.delete(
      text,
      {
        spjangcd: this.state.focused?.spjangcd,
        snddate: this.state.focused?.snddate,
        sndnum: this.state.focused?.sndnum,
      },
    ) && this.onRetrieveEvent(RetrieveFocusType.END);
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.ManageDepositList.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery,
      gubun: this.state.gubun,
      spjangcd: this.state.spjangcd,
      bankcd: this.state.bankcd,
      compflag: this.state.compflag,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.ManageDepositList.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      as_nm: this.state.searchQuery,
      gubun: this.state.gubun,
      spjangcd: this.state.spjangcd,
      bankcd: this.state.bankcd,
      compflag: this.state.compflag,
    });
  }

  @action
  onRowFocusEvent(item: ManageDepositModel, index: number) {
    this.setState({
      focused: item,
      focusIndex: index,
    });
  }

  onMainTableScroll(posY: number) {
    this.mainTablePosY = posY;
  }

  @action
  async onUpdatedRows(rows: Array<ManageDepositModel>, updatedRows: Array<ManageDepositModel>) {
    this.updatedRows = updatedRows;
    this.lastRows = this.state.ManageDepositList;
    this.setState({ ManageDepositList: rows });
  }

  @action
  async onMessageEvent(_: string, message: string) {
    const { actionStore: api } = this.props;

    const json = JSON.parse(JSON.parse(message));
    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message.replace(/\\n/ig, '\n'), '', ConfirmType.QUESTION)) {
        return;
      }

      await api.fxExec(
        'wb_account_ok',
        {
          stdate: this.state.stdate,
          enddate: this.state.enddate,
        }, false,
      );
      this.onSearchEvent();
    }
  }

  @action
  async onButtonEvent() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec(
      'wb_account',
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
      },
    );
    data && ConfirmWarning.show(data.titlebox, data.messagebox);
  }

  // 입출금처리 상태에 따른 처리
  @action
  async onMismijchkClicked(rowUpdate: RowUpdate, row: ManageDepositModel, item: any) {
    const { actionStore: api } = this.props;

    await this.SS({ focused: row });
    rowUpdate({ mismijchk: item });

    if (row.mismijchk === '1' && row.compflag !== '0') {
      if (AskType.YES !== await Confirm.ask('확인', '이미 입출금 처리 되었습니다. 취소하시겠습니까?', '예', '아니오')) {
        this.lastRows && await this.SS({ ManageDepositList: this.lastRows });
        this.updatedRows = [];
        this.table.current?.update(true);
        return;
      }
    }

    const data = await api.fxExec(
      'dw_list_itemchanged',
      {
        ...row,
        // @ts-ignore
        ...this.updatedRows[0],
        mismijchk: item,
        itemname: 'mismijchk',
        data: item,
      },
    );

    // 구분이 현금일경우 messagebox값에 따른 조건
    if ((row.mismijchk !== '1' && data?.messagebox.indexOf('현금금액') === -1)
      || (row.compflag === '0' && data?.messagebox.indexOf('현금금액') === -1)) {
      this.onModalEvent(true);
    } else {
      this.onRetrieveEvent();
    }
  }

  @action
  async onCltcdChanged(rowUpdate: RowUpdate, x: ManageDepositModel, item: CommonSelectorItem) {
    const { actionStore: api } = this.props;
    await api.fxExec(
      'dw_list_itemchanged',
      {
        snddate: x.snddate,
        sndnum: x.sndnum,
        cltcd: item.cd,
        cltnm: item.cdnm,
        itemname: 'cltcd',
        data: item.cd,
      },
    );

    rowUpdate({
      cltcd: item.cd,
      cltnm: item.cdnm,
    });
  }

  @action
  onModalEvent(isOpen: boolean) {
    if (this.state.focused?.gubun === '1') {
      this.setState({
        depositVisible: isOpen,
        resuamt: 0,
        janamt: '',
      });
    } else {
      this.setState({
        withdrawVisible: isOpen,
        resuamt: 0,
        janamt: '',
      });
    }
    this.onModalRetrive();
  }

  @action
  async onModalRetrive() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec(
      'retrieve',
      {
        sub: 'w_popup_ca642_ats',
        ...this.state.focused,
        // @ts-ignore
        cltcd: !this.state.focused?.cltcd ? this.updatedRows[0]?.cltcd : this.state.focused?.cltcd,
        // @ts-ignore
        cltnm: !this.state.focused?.cltnm ? this.updatedRows[0]?.cltnm : this.state.focused?.cltnm,
      },
    );

    this.setState({
      popupList: data?.items ? [...data.items] : [],
      data,
      janamt: data?.st_janamt || '0',
      resuamt: data?.st_resuamt || '0',
      misamt_tot: data?.misamt_tot || '0',
      janamt_tot: data?.janamt_tot || '0',
      mijamt_tot: data?.mijamt_tot || '0',
      inamt_tot: data?.inamt_tot || '0',
      wbamt_tot: data?.wbamt_tot || '0',
      whamt_tot: data?.whamt_tot || '0',
      wgamt_tot: data?.wgamt_tot || '0',
    }, async () => {
      await this.table2.current?.update(true);
      this.table2.current?.setFocus(0, 0);
    });
  }

  @action
  async onModalSaveEvent() {
    const { actionStore: api } = this.props;

    let warning: boolean;
    this.state.focused?.gubun === '1'
      ? warning = await Confirm.show('확인', '입금처리하시겠습니까?', ConfirmType.QUESTION)
      : warning = await Confirm.show('확인', '지급처리하시겠습니까?', ConfirmType.QUESTION);
    if (!warning) {
      await this.onRetrieveEvent();
      return;
    }

    if (warning) {
      const chkData: ManageDepositModel[] = [];
      const chkData2: ManagePopupModel[] = [];

      if (this.state.focused?.gubun !== '1') {
        this.state.popupList.forEach((x: any) => {
          if (x?.chk === '1') {
            chkData2.push(new ManagePopupModel({
              new: x.new,
              chk: x.chk,
              mijdate: x.mijdate,
              mijnum: x.mijnum,
              imseq: x.imseq,
              remark: x.remark,
              whamt: x.whamt,
              wbamt: x.wbamt,
              billkind: x.billkind,
              divicd: x.divicd,
              // @ts-ignore
              cltcd: this.updatedRows[0]?.cltcd,
              // @ts-ignore
              cltnm: this.updatedRows[0]?.cltnm,
            }));
          }
        });
      } else {
        this.state.popupList.forEach((x: any) => {
          if (x?.chk === '1') {
            chkData.push(new ManageDepositModel({
              ...x,
              // @ts-ignore
              cltcd: this.updatedRows[0]?.cltcd,
              // @ts-ignore
              cltnm: this.updatedRows[0]?.cltnm,
              gubun2: '1',
            }));
          }
        });
      }

      if (await api.save({
        // @ts-ignore
        new: '0',
        sub: 'w_popup_ca642_ats',
        // @ts-ignore
        ...this.updatedRows[0],
        janamt: this.state.janamtChk ? '0' : this.state.janamt,
        mismijdate: this.state.focused?.snddate,
        mismijnum: this.state.focused?.sndnum,
        items: this.state.focused?.gubun !== '1' ? chkData2 : chkData,
      }, true)) {
        this.table2.current?.resetUpdates();
      }

      this.setState({
        depositVisible: false,
        withdrawVisible: false,
        janamtChk: false,
      });
      await this.onRetrieveEvent();
    }
  }

  @action
  onRowFocusEvent2(item: ManagePopupModel) {
    this.setState({ popupFocused: new ManagePopupModel(item) });
  }

  @action
  async itemchanged(dw: Array<ManagePopupModel>) {
    let items = dw.sort((a, b) => (a.winamt < b.winamt ? 1 : a.winamt > b.winamt ? -1 : 0));

    items = items.map((x) => new ManagePopupModel({
      ...x,
      winamt: x.chk === '1' ? x.whamt : '0',
      whamt: x.chk === '1' ? x.whamt : '0',
      wbamt: x.chk === '1' ? x.wbamt : '0',
      wgamt: x.chk === '1' ? x.wgamt : '0',
    }, x.isNew));

    // List에서 예금, 현금 금액 값 가져와서 계산
    let ld_hamt = Format.toNumber(this.state.focused?.hamt || '0');
    let ld_bamt = Format.toNumber(this.state.focused?.bamt || '0');
    let ld_total_amt = ld_hamt + ld_bamt;
    const st_total_amt = ld_total_amt;

    items = items.map((x) => {
      if (x.chk === '1') {
        if (ld_total_amt === 0) return x;

        const winamt = Format.toNumber(x.winamt);
        if (winamt > 0) {
          ld_hamt -= Format.toNumber(x.whamt);
          ld_bamt -= Format.toNumber(x.wbamt);
          ld_total_amt -= winamt;
          return x;
        }

        const n = new ManagePopupModel(x, x.isNew);

        let ld_janamt = Format.toNumber(n.janamt);
        if (ld_hamt !== 0) {
          if (ld_janamt < ld_hamt) { // 현금
            n.winamt = (ld_janamt).toString(10);
            n.whamt = ld_janamt.toString(10);
            ld_total_amt -= ld_janamt;
            ld_hamt -= ld_janamt;
            ld_janamt -= ld_janamt;
          } else {
            n.winamt = (ld_hamt).toString(10);
            n.whamt = ld_hamt.toString(10);
            ld_total_amt -= ld_hamt;
            ld_janamt -= ld_hamt;
            ld_hamt = 0;
          }
        }

        if (ld_janamt === 0) return n;
        if (ld_total_amt === 0) return n;

        if (ld_bamt !== 0) {
          if (ld_janamt < ld_bamt) { // 예금
            n.winamt = (Format.toNumber(n.winamt) + ld_janamt).toString(10);
            n.wbamt = ld_janamt.toString(10);
            ld_total_amt -= ld_janamt;
            ld_bamt -= ld_janamt;
            // ld_janamt -= ld_janamt;
          } else {
            n.winamt = (Format.toNumber(n.winamt) + ld_bamt).toString(10);
            n.wbamt = ld_bamt.toString(10);
            ld_total_amt -= ld_bamt;
            // ld_janamt -= ld_bamt;
            ld_bamt = 0;
          }
        }
        return n;
      }
      return x;
    });

    // 오름차순정렬
    const sequenceData = items.sort((a, b) => (a.misdate < b.misdate ? -1 : a.misdate > b.misdate ? 1 : 0));

    this.setState({
      popupList: sequenceData,
      resuamt: st_total_amt - ld_total_amt,
      janamt: (ld_total_amt).toString(),
    });
    await this.table2.current?.update(false);
  }

  @action
  async onValueChangeEvent(data: string) {
    if (data === '1') {
      const resuamtData = this.state.resuamt + 500;
      const janamtData = parseInt(this.state.janamt, 10) - 500;
      this.setState({
        bmar: '500',
        resuamt: resuamtData,
        janamt: String(janamtData),
      });
    } else {
      const resuamtData = this.state.resuamt - 500;
      const janamtData = parseInt(this.state.janamt, 10) + 500;
      this.setState({
        bmar: '',
        resuamt: resuamtData,
        janamt: String(janamtData),
      });
    }
  }

  @action
  async onCltcdPopupOpen(isOpen: boolean) {
    await this.SS({
      cltcdPopupIsvisible: isOpen,
    });

    await this.onCltcdPopupRetrieve();
  }

  @action
  async onCltcdPopupRetrieve() {
    const { actionStore: api } = this.props;

    await this.SS({
      cltcdPopupData: [],
    });

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_popup_ca642_ats_cltcd',
        as_nm: this.state.popupSearchQuery,
        rtclafi: this.state.rtclafiChk === '0' ? '001' : '%',
      });

    await this.SS({
      cltcdPopupData: data?.items,
    });

    await this.cltcdPopupTable.current?.update(true);
    this.cltcdPopupTable.current?.setFocus(0);
  }

  @action
  async onCltcdPopupRowfocusEvent(item: CltcdPopupModel) {
    await this.SS({
      cltcdPopupFocused: item,
    });
  }

  @action
  async onCltcdPopupClose() {
    await this.SS({
      cltcdPopupFocused: new CltcdPopupModel(),
      cltcdPopupData: [],
      popupSearchQuery: '',
      rtclafi: '001',
      cltcdPopupIsvisible: false,
    });
  }

  @action
  async onCltcdUpdated() {
    // 거래처코드 팝업창에서 선택시 List 거래처코드 자동 입력.
    await Update.byIndex(this, 'ManageDepositList', this.state.focusIndex, 'cltcd', this.state.cltcdPopupFocused?.cltcd);
    await Update.byIndex(this, 'ManageDepositList', this.state.focusIndex, 'cltnm', this.state.cltcdPopupFocused?.cltnm);
    await this.table.current?.update(true);
    this.table.current?.setFocus(this.state.focusIndex);
    await this.onCltcdPopupClose();
  }

  @action
  async artcdOpenModal(item: any, search: string, rowUpdate: RowUpdate) {
    if (item.gubun === '2') {
      const { modalStore } = this.props;
      const tempBinding = new SearchBinding(
        modalStore,
        'TB_CA648_ARG',
        {},
        true,
        (item) => {
          rowUpdate({
            artcd: item.cd,
            artnm: item.cdnm,
          });
        },
      );
      tempBinding.open({
        as_nm: search,
      });
    } else {
      this.artRowUpdate = rowUpdate;
      const { actionStore: api } = this.props;
      // 매출구분 리스트 가져오기
      const data = await api.dropdown('wf_dd_da020_notjgubun');
      this.setState({ gubuns: data?.items || [] });
      await this.artModalOpen();
    }
  }

  @action
  async artModalOpen() {
    this.setState({
      isArtModal: true,
      artModalData: this.state.gubuns,
    });

    this.artModalGrid.current?.setFocus(0);
  }

  @action
  async onArtModalRowFocus(item: any) {
    await this.SS({
      artFocusedData: item,
    });
  }

  @action
  async onChangeArt(item?: any) {
    // await Update.byIndex(this, 'ManageDepositList', this.state.focusIndex, 'artcd', this.state.artFocusedData.artcd);
    // await Update.byIndex(this, 'ManageDepositList', this.state.focusIndex, 'artnm', this.state.artFocusedData.artnm);

    this.artRowUpdate && this.artRowUpdate({
      artcd: item ? item.artcd : this.state.artFocusedData.artcd,
      artnm: item ? item.artnm : this.state.artFocusedData.artnm,
    });

    await this.SS({
      isArtModal: false,
    });
    await this.table.current?.update(true);
  }

  render() {
    return (
      <ManageDepositTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...this.state,
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
