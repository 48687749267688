import * as React from 'react';
import { IoIosPin, IoMdCamera } from 'react-icons/all';
import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  FlexLayout,
  GridLayout,
  LabelBox,
  LayoutTitle,
  TableLayout,
  TelnumTextBox,
  TextBox,
  SearchLayout, AddressButton,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';

import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { CustomerRegister } from './CustomerRegister';

import { SearchBinding } from '../../../../models/common';

const regflags = [
  { value: '0', remark: '현장' },
  { value: '1', remark: '거래처' },
  { value: '2', remark: '직원' },
  { value: '3', remark: '일반' },
  { value: '4', remark: '기타' },
  { value: '5', remark: '영업' },
];


/**
 * 화면
 * @window w_tb_e911
 * @category 고객등록
 */
export const CustomerRegisterTemplate: React.FC<TemplateProps<CustomerRegister>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.focusedData;

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout style={{ width: 800 }}>
          <FlexLayout size={230}>
            <span style={{ width: 60 }}>그룹분류</span>
            <ComboBox
              value={scope.state.groupcd}
              data={[
                { value: '%', remark: '전체' },
                { value: '0', remark: '현장' },
                { value: '1', remark: '거래처' },
                { value: '2', remark: '직원' },
                { value: '3', remark: '일반' },
                { value: '4', remark: '기타' },
                { value: '5', remark: '영업' },
              ].map((x) => new ComboBoxModel(x.value, x.remark))}
              onSelect={(option) => scope.setState({ groupcd: option.value },
                () => scope.onRetrieveEvent())}
            />
          </FlexLayout>

          <FlexLayout size={230}>
            <span>전화분류</span>
            <ComboBox
              value={scope.state.telcd}
              style={{
                padding: '0 0 0 6px',
                fontSize: 12,
              }}
              data={scope.state.telcds?.map((y) => new ComboBoxModel(y.telcd, y.telnm))}
              onSelect={(option) => scope.setState({ telcd: option.value },
                () => scope.onRetrieveEvent())}
            />
          </FlexLayout>

          <FlexLayout size={500}>
            <span>검색어</span>
            <TextBox
              placeholderColor="#CACACA"
              placeholderAlign="left"
              placeholder="고객, 비고(담당자), 전화번호, 핸드폰번호, 팩스번호로 검색하세요"
              value={scope.state.searchQuery}
              onChange={(value) => scope.setState({ searchQuery: value })}
              onEnter={() => scope.onRetrieveEvent()}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'regflag',
              text: '고객구분',
              width: 10,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {regflags.filter((y) => y.value === x.regflag)[0]?.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'actcd',
              text: '고객코드',
              width: 15,
              render: (x) => (
                <FlexLayout justify="center" align="center">
                  {x.actcd}
                </FlexLayout>
              ),
            }, {
              id: 'actmail',
              text: '고객명',
              width: 60,
              render: (x) => (
                <FlexLayout justify="left" align="center">
                  {x.actmail}
                </FlexLayout>
              ),
            },
            {
              id: 'cltcd',
              text: '연결코드',
              width: 15,
              render: (x) => (
                <FlexLayout justify="left" align="center">
                  {x.cltcd}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.customerRegisterList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
        />

        <FlexLayout isVertical={true} weight={1.5}>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="그룹코드" isNeed={true} size={250}>
              <ComboBox
                readonly={!scope.state.data.isNew}
                value={model?.regflag}
                data={regflags.map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => setData({ regflag: option.value }, () => scope.onItemChanged())}
              />
            </LabelBox>
            <LabelBox title="고객번호" isNeed={true} size={250}>
              <TextBox
                textAlign="center"
                value={model?.actcd}
                readonly={true}
                onChange={(value) => setData({ actcd: value })}
              />
            </LabelBox>
            <LabelBox title="연결코드(거래처,현장,직원)" isNeed={true} style={{ color: 'var(--color-red)' }}>
              <TextBox
                readonly={model?.regflag === '3' || model?.regflag === '4' || model?.regflag === '5'}
                size={108}
                textAlign="center"
                value={model?.cltcd}
                onChange={(value) => setData({ cltcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  model?.regflag === '0' ? 'TB_E601_1' : model?.regflag === '1' ? 'TB_XCLIENT' : 'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      cltcd: item.cd,
                      actmail: item.cdnm,
                      custnm: item.cdnm,
                    });
                    scope.updateActmailFocusedRegisterRow(item.cdnm);
                  },
                )}
              />
              <TextBox
                value={model?.actmail}
                readonly={model?.regflag === '0' || model?.regflag === '1' || model?.regflag === '2'}
                onBlur={() => model?.new === '1' && setData({ custnm: model?.actmail })}
                onChange={(value) => setData({ actmail: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="별명">
              <TextBox
                textAlign="left"
                value={model?.nicknm}
                onChange={(value) => setData({ nicknm: value })}
              />
            </LabelBox>
            <LabelBox title="회사명">
              <TextBox
                value={model?.custnm}
                onChange={(value) => setData({ custnm: value })}
                readonly={(model?.regflag === '0') || (model?.regflag === '1')}
              />
            </LabelBox>
            <LabelBox title="부서명" size={125}>
              <TextBox
                value={model?.divinm}
                onChange={(value) => setData({ divinm: value })}
              />
            </LabelBox>
            <LabelBox title="직위" size={125}>
              <TextBox
                value={model?.rspnm}
                onChange={(value) => setData({ rspnm: value })}
              />
            </LabelBox>
            <LabelBox title="담당자" size={125}>
              <TextBox
                value={model?.perid}
                onChange={(value) => setData({ perid: value })}
                readonly={(model?.regflag === '0') || (model?.regflag === '1')}
              />
            </LabelBox>
            <LabelBox title="팩스번호" size={125}>
              <TextBox
                value={model?.fax}
                onChange={(value) => setData({ fax: value })}
                readonly={(model?.regflag === '0') || (model?.regflag === '1')}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="주소"
              size={500}
              style={{ color: 'var(--color-red)' }}
              footer={<FlexLayout>
                <AddressButton
                  publicStore={scope.props.publicStore}
                  modalStore={scope.props.modalStore}
                  onChange={(address) => setData({
                    zipcd: address.zonecode,
                    oldaddress: address.address,
                    address2: address.jibunAddress,
                    lat: address.y,
                    lng: address.x,
                  })}
                >
                  주소검색
                </AddressButton>
                <Button
                  isIcon={true}
                  style={{ fontSize: 24 }}
                  onClick={() => window.open(`http://map.daum.net/link/map/${model?.spjangnm},${model?.lat},${model?.lng}`)}
                >
                  <IoIosPin/>
                </Button>
                <Button
                  isIcon={true}
                  style={{ fontSize: 24 }}
                  onClick={() => window.open(`http://map.daum.net/link/roadview/${model?.spjangnm},${model?.lat},${model?.lng}`)}
                >
                  <IoMdCamera/>
                </Button>
              </FlexLayout>}
            >
              <TextBox
                textAlign="center"
                value={model?.zipcd}
                size={100}
                onChange={(value) => setData({ zipcd: value })}
                readonly={true}
              />
              <TextBox
                size={240}
                value={model?.oldaddress}
                readonly={true}
                onChange={(value) => setData({ oldaddress: value })}
              />
              <TextBox
                size={140}
                value={model?.address2}
                onChange={(value) => setData({ address2: value })}
                readonly={(model?.regflag === '0') || (model?.regflag === '1')}
              />
            </LabelBox>
            <LabelBox title="회사메일">
              <TextBox
                value={model?.email}
                onChange={(value) => setData({ email: value })}
                readonly={(model?.regflag === '0') || (model?.regflag === '1')}
              />
            </LabelBox>
            <LabelBox title="개인메일">
              <TextBox
                value={model?.memail}
                onChange={(value) => setData({ memail: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="메모" size={500}>
              <TextBox
                textAlign="left"
                value={model?.remark}
                onChange={(value) => setData({ remark: value })}
              />
            </LabelBox>
            <LabelBox title="생일">
              <TextBox
                value={model?.birthday}
                onChange={(value) => setData({ birthday: value })}
              />
            </LabelBox>
            <LabelBox title="홈페이지">
              <TextBox
                value={model?.homepage}
                onChange={(value) => setData({ homepage: value })}
              />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
        <FlexLayout justify="left" weight={3.5} align="center">
          <LayoutTitle size={170}>전화번호목록</LayoutTitle>
          <span style={{ marginLeft: 150, color: '#EE1A23' }}>
            *저장시 가장 처음 전화번호는 해당 그룹[현장,거래처, 직원]에 자동 업데이트 됩니다.</span>
        </FlexLayout>
        <FlexLayout justify="right" weight={3}>
          <Button
            style={{ height: '25px', width: '60px' }}
            onClick={() => scope.dw_2_new()}
          >
            추가
          </Button>

          <Button
            style={{ height: '25px', width: '60px' }}
            theme={BUTTON_THEMES.RED}
            onClick={() => scope.dw_2_delete()}
          >
            삭제
          </Button>
        </FlexLayout>
      </FlexLayout>

      <TableLayout
        ref={scope.table}
        header={[
          {
            id: 'seq',
            text: 'No',
            width: 8,
            render: (x) => (
              <FlexLayout justify="center" align="center">
                {x.seq}
              </FlexLayout>
            ),
          },
          {
            id: 'tel',
            text: '전화번호',
            width: 17,
            render: (x, rowUpdate, refs) => (
              <TelnumTextBox
                ref={refs}
                value={x.tel}
                textAlign="center"
                onChange={(value) => rowUpdate({ tel: value })}
              />
            ),
          },
          {
            id: 'telcd',
            text: '분류',
            width: 17,
            render: (x, rowUpdate) => (
              <ComboBox
                value={x.telcd}
                style={{
                  padding: '0 0 0 6px',
                  fontSize: 12,
                }}
                data={scope.state.telcds2?.map((y) => new ComboBoxModel(y.telcd, y.telnm))}
                onSelect={(option) => rowUpdate({ telcd: option.value })}
              />
            ),
          },
          {
            id: 'keynum',
            text: '대표',
            width: 8,
            render: (x, rowUpdate) => (
              <CheckBox value={x.keynum === '1'} onChange={(v) => rowUpdate({ keynum: v ? '1' : '0' })}/>
            ),
          },
          {
            id: 'remark',
            text: '비고(담당자)',
            width: 50,
            render: (x, rowUpdate, refs) => (
              <TextBox
                ref={refs}
                value={x.remark}
                textAlign="left"
                onChange={(value) => rowUpdate({ remark: value })}
              />
            ),
          },
        ]}
        data={scope.state.customerRegisterDetailList}
        infinityHandler={scope.infinity2}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        onChange={(rows, updatedRows) => {
          scope.onUpdatedRows(rows, updatedRows);
        }}
        onRowFocusEvent={(item) => scope.onDetailRowFocusEvent(item)}
      />
    </FlexLayout>
  );
};
