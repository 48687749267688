import * as React from 'react';
import { action } from 'mobx';
import {
  StockDetailModel,
  StockModel,
  StockPopupModel,
} from './models';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
  RowUpdate,
} from '../../../../constants';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { StockTemplate } from './Stock.template';
import { ConfirmWarning } from '../../../../utils/confirm';
import { Format } from '../../../../utils/string';
import { PageComponent } from '../../../../utils';
import {
  Date8,
  DateStabilizer,
  Today,
} from '../../../../utils/time';
import { Update } from '../../../../utils/array';

export enum StockItemChangeTypes {
  IBGDATE,
  TAXCLS,
  PERID,
  ACTCD,
  QTY,
  UAMT,
  SAMT,
  PCODE,
  PSERIAL
}

export enum StockItemChangeTypeNames {
  ibgdate,
  taxcls,
  perid,
  actcd,
  qty,
  uamt,
  samt,
  pcode,
  pserial,
}

interface StockState {

  // 검색 조건(메인)
  searchQuery: string;
  stdate: string;
  curdate: string;
  enddate: string;
  store: string;
  storenm: string;

  // 검색 조건(팝업)
  popupSearchQuery: string;
  ibgdate: string;

  // 데이터 객체
  focusedDetailIndex: number;
  focusedStockDetail?: StockDetailModel;
  focusedStock?: StockModel;
  data: StockModel;
  lastNewData: StockModel;
  stockList: Array<StockModel>;
  stockDetailList: Array<StockDetailModel>;
  popupList: Array<StockPopupModel>;
  popupFocused?: StockPopupModel;
  taxcls: string;

  // 팝업
  loadOrderModal: boolean; // 발주서 불러오기

  samt_tot: string;
  tamt_tot: string;
  mamt_tot: string;

  // 발주서 불러오기 후 조회 시 포커스용
  popupIbgdate: string;
  popupIbgcltnm: string;
}

/**
 * 컨트롤러
 * @window w_tb_ca611_01
 * @category 입고등록
 */
export class Stock extends PageComponent<PageProps, StockState>
  implements PageToolEvents {
  updatedRows?: Array<StockDetailModel>;

  updatedRows2?: Array<StockPopupModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  table: React.RefObject<TableLayout> = React.createRef();

  popupTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinity3?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      curdate: `${year}${month}${date}`,
      stdate: `${year}${month}01`,
      enddate: `${year}${month}${date}`,
      searchQuery: '',
      store: '',
      storenm: '',

      popupSearchQuery: '',
      ibgdate: `${year}${month}${date}`,

      focusedDetailIndex: 0,
      data: new StockModel(),
      stockList: [],
      stockDetailList: [],

      samt_tot: '',
      tamt_tot: '',
      mamt_tot: '',

      popupIbgdate: '',
      popupIbgcltnm: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        store: this.state.store,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          stockList: [...this.state.stockList, ...items],
        }, next);
      },
      async () => {
        await this.SS({
          stockList: [],
          stockDetailList: [],
          data: new StockModel(),
        });

        await this.infinity?.retrieveAll();

        this.state.stockList.length > 0
          && this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.data;

    this.setState({
      stockList: [],
      stockDetailList: [],
      data: new StockModel(),
      focusedStock: new StockModel(),
    }, async () => {
      let index = await this.infinity?.retrieveTo(['ibgdate', 'cltnm'], [lastSelected?.ibgdate, lastSelected?.cltnm], type, true) || 0;
      if (this.state.stockList && this.state.stockList.length > index) {
        if (this.state.popupIbgdate.trim() === '' && this.state.popupIbgcltnm === '') {
          this.grid.current?.setFocus(index);
        } else {
          for (let i = 0; i < this.state.stockList.length; i += 1) {
            if (this.state.stockList[i].ibgdate === this.state.popupIbgdate && this.state.stockList[i].cltnm === this.state.popupIbgcltnm) {
              index = i;
              break;
            }
          }
        }
      }
      this.grid.current?.setFocus(index);
      await this.table?.current?.update(true);
    });
  }

  @action
  onRowFocusEvent(item: StockModel) {
    if (item?.new === '1') {
      this.setState({
        focusedStockDetail: undefined,
        stockDetailList: [],
        data: this.state.lastNewData,
      });
      this.table.current?.update();
      return;
    }

    const { actionStore: api } = this.props;
    this.updatedRows = [];

    this.setState(
      { focusedStock: item },
      async () => {
        this.infinity2 = new InfinityRetrieve(
          item,
          (params) => api.fxExec('dw_1_RowFocuschanged', params),
          (items) => {
            this.setState({
              stockDetailList: items.map((x: any, index: number) => new StockDetailModel({
              // 순번 생성
                ...x,
                // eslint-disable-next-line no-nested-ternary
                ibgseq: index < 9 ? `00${index + 1}` : index < 99 ? `0${index + 1}` : (index + 1).toString(),
              })),
            });
          },
          async () => {
            await this.SS({ stockDetailList: [] });
            await this.infinity2?.retrieve();

            this.table.current?.update();
            this.table.current?.setFocus(0, 0);
          },
        );
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      stockDetailList: [],
    }, async () => {
      const data = await this.infinity2?.retrieve();
      this.setState({
        data: new StockModel(data),
        samt_tot: this.infinity2?.box?.samt_tot || '0',
        tamt_tot: this.infinity2?.box?.tamt_tot || '0',
        mamt_tot: this.infinity2?.box?.mamt_tot || '0',
        taxcls: item?.taxcls,
      });

      if (!this.state.stockDetailList.length) {
        const data = await api.fxExec('dw_1_RowFocuschanged',
          { ...item });
        this.setState({
          data: new StockModel(data),
          samt_tot: data?.samt_tot || '0',
          tamt_tot: data?.tamt_tot || '0',
          mamt_tot: data?.mamt_tot || '0',
          taxcls: item?.taxcls,
        });
      }

      this.table.current?.update();
      this.table.current?.setFocus(0, 0);
    });
  }

  @action
  onRowFocusEvent2(item:StockDetailModel) {
    this.setState({
      focusedStockDetail: item,
    });
  }

  @action
  async onSaveEvent() {
    let zeroQty = false;

    if (!this.state.data.cltnm) {
      ConfirmWarning.show('경고', '발주처를 확인해주세요.');
      return;
    }
    if (this.state.stockDetailList.length < 1) {
      ConfirmWarning.show('경고', '입고품목을 입력해주세요.');
      return;
    }

    this.state.stockDetailList.forEach((x) => {
      if (x.qty === '0') {
        zeroQty = true;
      }
    });

    if (zeroQty) {
      ConfirmWarning.show('경고', '수량이 0인 품목이있습니다. \n 수량을 입력해주세요');
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.save({
      ...this.state.data,
      items: this.state.stockDetailList,
    }, this.state.data?.new === '1')) {
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.data.ibgdate);
      this.setState({
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
      });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onNewEvent() {
    if (this.state.searchQuery !== '' || this.state.enddate < Today.date() || this.state.store !== '') {
      await this.SS({
        searchQuery: '',
        stdate: `${Date8.make().substr(0, 6)}01`,
        enddate: Date8.make(),
        store: '',
      });
      await this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }

    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.new();
    if (data) {
      const newModel = new StockModel(data, true);

      this.setState({
        data: newModel,
        lastNewData: newModel,
        stockList: [
          newModel,
          ...this.state.stockList,
        ],
        stockDetailList: [],
        focusedStock: newModel,
      }, async () => {
        await this.table.current?.update(true);
        this.grid.current?.setFocus(0);
      });
    }
  }

  @action
  async onNewEvent2() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec(
      'dw_3_new',
      {
        ibgdate: this.state.data.ibgdate,
        ibgnum: this.state.data.ibgnum,
        ibgseq: this.state.focusedStockDetail?.ibgseq,
      },
    );
    const seq = this.state.stockDetailList.length;
    data && this.setState({
      stockDetailList: [...this.state.stockDetailList,
        // eslint-disable-next-line no-nested-ternary,max-len
        new StockDetailModel({ ...data, ibgseq: seq < 9 ? `00${seq + 1}` : seq < 99 ? `0${seq + 1}` : (seq + 1).toString() }, true)],
    }, async () => {
      await this.table.current?.update(true);
      this.table.current?.setFocus(this.state.stockDetailList.length - 1, 0);
    });
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `입고일자: ${this.state.focusedStock?.ibgdate}, 입고번호: ${this.state.focusedStock?.ibgnum}`;

    await api.fxDelete(
      'delete',
      text,
      {
        ibgdate: this.state.data.ibgdate,
        ibgnum: this.state.data.ibgnum,
        store: this.state.data.store,
        baldate: this.state.data.baldate,
        balnum: this.state.data.balnum,
        pumdate: this.state.data.pumdate,
        pumnum: this.state.data.pumnum,
      },
    );
    await this.onRetrieveEvent(RetrieveFocusType.FIRST);
  }

  @action
  async onDeleteEvent2() {
    const { actionStore: api } = this.props;
    if (this.state.stockDetailList.length === 0) {
      ConfirmWarning.show('경고', '삭제할 항목이 없습니다.');
      return;
    }

    // eslint-disable-next-line max-len
    const text = `${this.state.focusedStockDetail?.ibgseq} - ${this.state.focusedStockDetail?.pcode} - ${this.state.focusedStockDetail?.pname}`;
    await api.fxDelete(
      'dw_3_delete',
      text,
      {
        ibgdate: this.state.data?.ibgdate,
        ibgnum: this.state.data?.ibgnum,
        ibgseq: this.state.focusedStockDetail?.ibgseq,
        new: this.state.focusedStockDetail?.new,
        store: this.state.data?.store.trim(),
        pcode: this.state.focusedStockDetail?.pcode,
      },
    ) && this.onRowFocusEvent(this.state.data!);
  }


  @action
  async loadOrderModal(isOpen: boolean) {
    this.setState({ loadOrderModal: isOpen });
    isOpen && await this.modalRetrieve();
  }

  @action
  async modalRetrieve() {
    const { actionStore: api } = this.props;

    this.infinity3 = new InfinityRetrieve(
      {
        sub: 'w_popup_ca608_01',
        as_nm: this.state.popupSearchQuery,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          popupList: [...this.state.popupList, ...items.map((x) => new StockPopupModel(x))],
        }, () => this.popupTable.current?.update(false));
      },
      async () => {
        await this.SS({ popupList: [] });
        await this.infinity3?.retrieveAll();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      popupList: [],
    }, async () => {
      await this.infinity3?.retrieveAll();
    });
  }

  @action
  async modalDelete() {
    const { actionStore: api } = this.props;
    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_popup_ca608_01',
      items: this.updatedRows2,
    }) && await this.modalRetrieve();

    this.updatedRows2 = [];
  }

  @action
  async modalSave() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec('save',
      {
        sub: 'w_popup_ca608_01',
        as_nm: this.state.popupSearchQuery,
        ibgdate: this.state.ibgdate || '',
        items: this.state.popupList.filter((x) => x.chk === '1'),
      });

    if (data) {
      await this.setState({
        popupIbgdate: data?.ibgdate,
        popupIbgcltnm: data?.items[0]?.cltnm,
      });
      await this.loadOrderModal(false);
      await this.onRetrieveEvent();
      this.updatedRows2 = [];
    }
  }

  @action
  async chkBalSameAll(item: StockPopupModel, value: boolean, rowUpdate: RowUpdate) {
    const next = value ? '1' : '0';

    if (this.state.popupList.filter((x) => x.chk === '1' && (x.baldate !== item.baldate || x.balnum !== item.balnum)).length) {
      ConfirmWarning.show('에러', '한번에 하나의 발주서만 불러올 수 있습니다');
      rowUpdate({ value: value ? '0' : '1' });
      return;
    }

    for (let i = 0; i < this.state.popupList.length; i += 1) {
      const x = this.state.popupList[i];
      if (x.baldate === item.baldate && x.balnum === item.balnum) {
        // eslint-disable-next-line no-await-in-loop
        await Update.byIndex(this, 'popupList', i, 'chk', next);
      }
    }
    await this.popupTable.current?.update(false);
  }

  @action
  async itemChanged(type: number, _?: any) {
    const { actionStore: api } = this.props;
    let data = { new: undefined, items: [] };

    if (type === StockItemChangeTypeNames.ibgdate) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          itemname: StockItemChangeTypeNames[type],
          data: this.state.data.ibgdate,
          items3: this.state.stockDetailList.length.toString(), // detail list count
          new: this.state.data?.new,
        },
      );
    }

    if (type === StockItemChangeTypeNames.perid) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          itemname: StockItemChangeTypeNames[type],
          data: this.state.data.perid,
          new: this.state.data.new,
        },
      );
    }

    if (type === StockItemChangeTypeNames.actcd) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          itemname: StockItemChangeTypeNames[type],
          data: this.state.focusedStockDetail?.actcd,
          new: this.state.data.new,
        },
      );
    }

    if (type === StockItemChangeTypeNames.taxcls
    ) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          itemname: StockItemChangeTypeNames[type],
          data: this.state.data.taxcls,
          new: this.state.data.new,
          items: this.state.stockDetailList,
        },
      );
    }

    data && this.setState({
      data: new StockModel({
        ...this.state.data, // new = 0
        ...data, // new = 1
      }, data.new === '1'),
    });

    if (data?.items) {
      this.setState({
        stockDetailList: data.items.map((x: any) => new StockDetailModel(x)),
      }, () => this.table.current?.update());
    }
  }

  @action
  async tabAutoCalc(item: any, rowUpdate: RowUpdate, name: string) {
    const { actionStore: api } = this.props;

    const qty = Format.toNumber(item.qty);
    const samt = Format.toNumber(item.samt);
    const tamt = Format.toNumber(item.tamt);
    let uamt = Format.toNumber(item.uamt);

    let amount = 0;
    let sum = 0;
    let tax = 0;

    if (name === 'qty' || name === 'uamt') {
      if (this.state.taxcls === '1') {
        amount = Math.round((qty * uamt) / 1.1);
        tax = Math.round((qty * uamt) - amount);
        sum = amount + tax;
        uamt = Math.round(uamt / 1.1);
      } else {
        amount = Math.round(qty * uamt);
        tax = Math.round((qty * uamt) / 10);
        sum = amount + tax;
      }
    } else if (name === 'samt') {
      if (this.state.taxcls === '1') {
        amount = Math.round(samt / 1.1);
        tax = Math.round(samt - (samt / 1.1));
        sum = amount + tax;
      } else {
        amount = samt;
        tax = Math.round(samt / 10);
        sum = amount + tax;
      }
    } else {
      amount = samt;
      tax = tamt;
      sum = amount + tax;
    }

    rowUpdate({
      ...item,
      qty: qty.toString(),
      uamt: uamt.toString(),
      samt: amount.toString(),
      tamt: tax.toString(),
      mamt: sum.toString(),
    });

    if (name === 'qty') {
      await api.fxExec(
        'dw_3_itemchanged',
        {
          itemname: 'qty',
          data: qty.toString(),
          ibgdate: item?.ibgdate,
          ibgnum: item?.ibgnum,
          ibgseq: item?.ibgseq,
          uamt: uamt.toString(),
          samt: amount.toString(),
          tamt: tax.toString(),
          mamt: sum.toString(),
        },
      );
    }

    if (name === 'uamt') {
      await api.fxExec(
        'dw_3_itemchanged',
        {
          itemname: 'uamt',
          data: uamt.toString(),
          ibgdate: item?.ibgdate,
          ibgnum: item?.ibgnum,
          ibgseq: item?.ibgseq,
          uamt: uamt.toString(),
          samt: amount.toString(),
          tamt: tax.toString(),
          mamt: sum.toString(),
        },
      );
    }

    if (name === 'samt') {
      await api.fxExec(
        'dw_3_itemchanged',
        {
          itemname: 'samt',
          data: amount.toString(),
          ibgdate: item?.ibgdate,
          ibgnum: item?.ibgnum,
          ibgseq: item?.ibgseq,
          uamt: uamt.toString(),
          samt: amount.toString(),
          tamt: tax.toString(),
          mamt: sum.toString(),
        },
      );
    }
  }

  @action
  async detailItemChanged(type: number, value?: any, item?: any) {
    const { actionStore: api } = this.props;


    // eslint-disable-next-line max-len
    const focusedDetailIndex = this.state.stockDetailList.findIndex((x) => x.ibgseq === item?.ibgseq);
    let data = { new: undefined };

    if (type === StockItemChangeTypeNames.pserial) {
      data = await api.fxExec(
        'dw_3_itemchanged',
        {
          itemname: StockItemChangeTypeNames[type],
          data: value,
          ibgdate: this.state.data.ibgdate,
          ibgnum: this.state.data.ibgnum,
          ibgseq: this.state.data.ibgseq,
        },
      );
    }

    // if (type === StockItemChangeTypeNames.uamt
    //   || type === StockItemChangeTypeNames.qty) {
    //   data = await api.fxExec(
    //     'dw_3_itemchanged',
    //     {
    //       itemname: StockItemChangeTypeNames[type],
    //       data: '',
    //       taxcls: this.state.data.taxcls,
    //       ...item,
    //     },
    //   );
    // }

    if (type === StockItemChangeTypeNames.uamt) {
      data = await api.fxExec(
        'dw_3_itemchanged',
        {
          itemname: StockItemChangeTypeNames[type],
          data: value,
          ibgdate: item?.ibgdate,
          ibgnum: item?.ibgnum,
          ibgseq: item?.ibgseq,
          uamt: item?.uamt,
          samt: item?.samt,
          tamt: item?.tamt,
          mamt: item?.mamt,
        },
      );
    }

    if (type === StockItemChangeTypeNames.qty) {
      data = await api.fxExec(
        'dw_3_itemchanged',
        {
          itemname: StockItemChangeTypeNames[type],
          data: value,
          ibgdate: item?.ibgdate,
          ibgnum: item?.ibgnum,
          ibgseq: item?.ibgseq,
          uamt: item?.uamt,
          samt: item?.samt,
          tamt: item?.tamt,
          mamt: item?.mamt,
        },
      );
    }

    // if (type === StockItemChangeTypeNames.samt) {
    //   data = await api.fxExec(
    //     'dw_3_itemchanged',
    //     {
    //       itemname: StockItemChangeTypeNames[type],
    //       data: value,
    //       taxcls: this.state.data.taxcls,
    //       new: this.state.focusedStockDetail?.new,
    //     },
    //   );
    // }

    if (type === StockItemChangeTypeNames.samt) {
      data = await api.fxExec(
        'dw_3_itemchanged',
        {
          itemname: StockItemChangeTypeNames[type],
          data: value,
          ibgdate: item?.ibgdate,
          ibgnum: item?.ibgnum,
          ibgseq: item?.ibgseq,
          uamt: item?.uamt,
          samt: item?.samt,
          tamt: item?.tamt,
          mamt: item?.mamt,
        },
      );
    }

    if (type === StockItemChangeTypeNames.pcode) {
      data = await api.fxExec(
        'dw_3_itemchanged',
        {
          itemname: StockItemChangeTypeNames[type],
          cltcd: this.state.data.cltcd,
          data: this.state.focusedStockDetail?.pcode,
          new: this.state.focusedStockDetail?.new,
        },
      );
    }
    // 디테일 테이블에서 수정되어야하는 로우를 찾아 값 변경

    data && this.setState({
      focusedStockDetail: new StockDetailModel({
        ...this.state.focusedStockDetail,
      }, false),
      // eslint-disable-next-line max-len
      stockDetailList: this.state.stockDetailList.map((x: any, index: number) => (
        index === focusedDetailIndex
          ? new StockDetailModel({ ...x, ...data }, data.new === '1') : new StockDetailModel(x)
      )),
    }, () => this.table.current?.update(false));
  }

  @action
  async onProjectButtonClicked() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('dw_2_buttonclicked',
      {
        itemname: 'b_projno',
        data: '',
        ...this.state.data,
        cltcd: this.state.data?.cltcd,
        projno: this.state.data?.projno,
        projnm: this.state.data?.projnm,
        ibgdate: this.state.data?.ibgdate,
        ibgnum: this.state.data?.ibgnum,
      }, this.state.data?.isNew);

    data && this.setState({
      data: new StockModel({
        ...this.state.data,
        ...data,
      }, data.new === '1'),
    });
  }

  // /**
  //  * 행 변경 이벤트
  //  * @param rows      전체 행 (변경 행 반영된 상태)
  //  * @param updatedRows 변경 행들만
  //  */
  @action
  onUpdatedRows(rows: Array<StockDetailModel>, updatedRows: Array<StockDetailModel>) {
    this.updatedRows = updatedRows;
    this.setState({ stockDetailList: rows });
  }

  /**
   * 행 변경 이벤트
   * @param rows2      전체 행 (변경 행 반영된 상태)
   * @param updatedRows2 변경 행들만
   */
  @action
  onUpdatedRows2(rows2: Array<StockPopupModel>, updatedRows2: Array<StockPopupModel>) {
    this.updatedRows2 = updatedRows2;
    this.setState({ popupList: rows2 });
  }

  render() {
    return (
      <StockTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new StockModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
