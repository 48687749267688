import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import { LabelBox } from '../../../../components/forms/LabelBox';
import { TextBox } from '../../../../components/forms/TextBox';
import { DateTextBox } from '../../../../components/forms/DateTextBox';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { FlexLayout } from '../../../../components/layout/FlexLayout';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { Resister, ResisterItemChangeTypes } from './Resister';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  Date8,
} from '../../../../utils/time';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  FormatTextBox,
  OptionBox,
  TelnumTextBox,
} from '../../../../components';
import { SearchBinding } from '../../../../models';
/*
* 화면
* @window w_tb_e301
* @category   민원접수등록
*/

export const States = [
  { key: '0', value: '접수' },
  { key: '1', value: '배정' },
  { key: '2', value: '완료' },
];


export const ResisterTemplate: React.FC<TemplateProps<Resister>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.focusedDetailResister;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout>
          <SearchLayout>
            <FlexLayout size={200}>
              <span>기간</span>
              <DateTextBox
                value={scope.state.stdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ stdate: value })}
                onEnter={() => scope.onRetrieveEvent()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.stdate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ stdate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={180}>
              <span style={{ marginLeft: 8 }}>~</span>
              <DateTextBox
                value={scope.state.enddate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ enddate: value })}
                onEnter={() => scope.onRetrieveEvent()}
                head={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() - 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    const date = new Date(Date8.withDash(scope.state.enddate));
                    date.setDate(date.getDate() + 1);
                    scope.setState({ enddate: Date8.make(date) },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <FlexLayout size={200}>
              <span>결과</span>
              <ComboBox
                value={scope.state.resultck}
                data={[
                  { value: '%', remark: '전체' },
                  { value: '1', remark: '처리' },
                  { value: '0', remark: '미처리' },
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => scope.setState({ resultck: option.value },
                  () => scope.onRetrieveEvent())}
              />
            </FlexLayout>

            <FlexLayout>
              <span>검색어</span>
              <TextBox
                placeholderColor="#CACACA"
                placeholderAlign="left"
                placeholder="현장으로 검색하세요"
                value={scope.state.searchQuery}
                onChange={(value) => scope.setState({ searchQuery: value })}
                onEnter={() => scope.onRetrieveEvent()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <Button onClick={() => scope.onClickSMS()}>
              문자전송
            </Button>
          </SearchLayout>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout>
        <FlexLayout isVertical={true}>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'state',
                text: '상태',
                width: 10,
                render: (x) => (
                  <FlexLayout
                  >
                    <TextBox
                      textAlign="center"
                      color={x.state === '1' ? 'var(--color-orange)' : x.state === '2'
                        ? 'var(--color-blue)' : 'var(--color-red)'}
                      value={x.state === '1' ? '배정' : x.state === '2'
                        ? '완료' : '접수'}
                    />
                  </FlexLayout>
                ),
              },
              {
                id: 'recedate',
                text: '접수일자',
                width: 20,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.recedate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'recenum',
                text: '번호',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.recenum}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 30,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'compdate',
                text: '완료일자',
                width: 20,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.compdate)}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.ResisterList}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item) => scope.onRowFocusEvent(item)}
          />
        </FlexLayout>
        <FlexLayout
          weight={2}
          isVertical={true}
         disabled={!model?.custcd && model?.isNew}
          disabledText="신규로 추가하거나 현장을 선택해주세요"
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="민원접수일자"
              isNeed={true}
            >
              <DateTextBox
                readonly={model?.new === '0'}
                value={model?.recedate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ recedate: value })}
              />
              <TextBox
                textAlign="center"
                value={model?.recenum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="접수시간">
              <FormatTextBox
                format="##:##"
                value={model?.recetime}
                textAlign="center"
                onChange={(value) => setData({ recetime: value })}
              />
            </LabelBox>
            <LabelBox title="접수자"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.reperid}
                textAlign="center"
                onChange={(value) => setData({ reperid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      reperid: item.cd,
                      repernm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                weight={1.7}
                textAlign="center"
                value={model?.repernm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="현장담당자">
              <TextBox
                value={model?.actperid}
                textAlign="center"
                onChange={(value) => setData({ actperid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      actperid: item.cd,
                      actpernm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                weight={1.7}
                textAlign="center"
                value={model?.actpernm}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="고객명(현장/민원신고자)"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.actcd}
                textAlign="center"
                onChange={(value) => setData({ actcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E601_1',
                  {},
                  true,
                  (item) => {
                    setData({
                      actcd: item.cd,
                      actnm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model?.actnm}
                weight={1.7}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="고객연락처"
              footer={<FlexLayout>
                <Button
                  style={{ color: 'var(--color-pink)' }}
                  onClick={() => scope.onClickSMS()}
                >
                  보내기
                </Button>
              </FlexLayout>}
            >
              <TelnumTextBox
                value={model?.acttelnum}
                textAlign="center"
                onChange={(value) => setData({ acttelnum: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="민원구분"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.civilcd}
                textAlign="center"
                onChange={(value) => setData({ civilcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E300',
                  {},
                  true,
                  (item) => {
                    setData({
                      civilcd: item.cd,
                      civilnm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model?.civilnm}
                weight={1.7}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="민원처리자"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.perid}
                textAlign="center"
                onChange={(value) => setData({ perid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      perid: item.cd,
                      pernm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                weight={1.7}
                textAlign="center"
                value={model?.pernm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="민원처리자부서"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.divicd}
                textAlign="center"
                onChange={(value) => setData({ divicd: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JC002',
                  {},
                  true,
                  (item) => {
                    setData({
                      divicd: item.cd,
                      divinm: item.cdnm,
                    });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                weight={1.7}
                textAlign="center"
                value={model?.divinm}
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="처리자배정">
              <OptionBox
                value={model!?.okflag}
                data={[
                  { value: '0', remark: '미배정' },
                  { value: '1', remark: '배정완료' },
                ]}
                onChange={(v) => setData({ okflag: v.value }, () => {
                  scope.itemChanged(ResisterItemChangeTypes.OKFLAG);
                })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout>
            <LabelBox title="민원상세내용">
              <TextBox
                value={model?.remark}
                isMultiline={true}
                textAlign="left"
                onChange={(value) => setData({ remark: value })}
              />
            </LabelBox>
          </FlexLayout>

        </FlexLayout>
      </FlexLayout>
      <FlexLayout
        size={250}
        disabled={!model?.custcd && model?.isNew}
        disabledText="신규로 추가하거나 현장을 선택해주세요"
      >
        <GridLayout
          ref={scope.detailGrid}
          header={[
            {
              id: 'recedate',
              text: '접수일자',
              width: 5,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.recedate)}
                </FlexLayout>
              ),
            },
            {
              id: 'recenum',
              text: '번호',
              width: 3,
              render: (x) => (
                <TextBox
                  value={x.recenum}
                  textAlign="center"
                />
              ),
            },
            {
              id: 'civilnm',
              text: '민원내용',
              width: 3,
              render: (x) => (
                <TextBox
                  value={x.civilnm}
                  textAlign="left"
                />
              ),
            },
            {
              id: 'remark',
              text: '비고',
              width: 15,
              render: (x) => (
                <TextBox
                  value={x.remark}
                  textAlign="left"
                />
              ),
            },
          ]}
          data={scope.state.ReportList}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          // onRowClick={(item) => scope.onYesterdayRowFocusEvent(item)}
        />
      </FlexLayout>

    </FlexLayout>
  );
};
